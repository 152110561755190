<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          Order detail ({{ order_number }})
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div v-for="(item, i) in other" :key="i">
          <pop-up-selector :item="item"></pop-up-selector>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PopUpSelector from "@/own/components/fulfillment/orders/popup/PopUpSelector.vue";
export default {
  name: "Others",
  components: { PopUpSelector },
  data: () => ({
    dialog: false,
    other: null,
  }),
  computed: {
    order_number() {
      try {
        return this.other[2].data[1].value;
      } catch {
        return null;
      }
    },
  },
  methods: {
    setData(otherData) {
      this.other = otherData;
    },
    toggleModal() {
      if (this.dialog) {
        this.isLoaded = false;
        this.dialog = !this.dialog;
        this.other = null;
      } else {
        this.dialog = !this.dialog;
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
.other_key {
  margin: 2px;
}
.other_value {
  font-weight: bold;
  margin: 2px;
  margin-left: 6px;
}
.main {
  background-color: rgb(245, 245, 245);
}
</style>
